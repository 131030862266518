/* globals $, config, attachFixedFocus, detachFixedFocus, detachMenuNumbers, attachInputEvents, attachMenuNumbers, createKeyAction,
 getProgramType, getLocationMapping, showAjaxLoading, hideAjaxLoading, pingServer, sendTransactions, showIncorrectTimeMessage, alert,
 countStoredTransactions, saveLocationIncidentTransaction, padNumber, isScannerTimeCorrect, logOut, getIncidentCodes, downloadAllocation,
 pickingAllocation, getAllocations, validateUser, confirm, console, validateCaskDetails, updatePickingAllocation, scrollAllocationList, showAjaxLoading,
 isValidWarehouse, trimAvailableAllocations, countTotalRequiredCasks, countTotalRequiredCasksWithoutAllocatedStock, validateAndSaveIncidentTransaction,
 doesProgramSendTransactions, dataToBarcode, saveAndCheckServerTime, createTransactionDateTime, getAppCacheStatus, downloadAllocationList, searchListForItem,
 refreshAllocationPage, getAllRemainingOrderLineCasks, createCompleteAction, validateTrailer, stripScanningPrefix, getRemainingCasks, detachFixedFocus,
 getProgramTransactionCode, scanLocation, getCurrentPage, showLevelCaskCount, scrollList, movement_casks, createScrollButtonsForContainer, previous_scan,
 processScanningEvent, getBarcodeMapping, areAllAllocationsDownloaded, showAlert, processScan, getManualIncidentGroups, setAllocations,
 getLabels, getWarehouseType, getCurrentLocation, saveCaskIncidentTransaction, createBarcodeObject, isApplicationInProgram, getTrailersFromStorage,
  findBarcodeMatch, palletsScanned, detachFixedFocus, showAjaxLoading, getConfig */
/**
 * Popup functions and template popup function.
 * Created by andrew.stalker on 02/10/2015.
 */
var popup = {modalsOpen:0};

/**
 * @description Define close and hidden handlers for a modal that has been coded on a screen. I.E. a modal where the HTML
 * has been hardcoded on a specific page, or has been loaded from a template.
 * @param {string} popupId - The element ID of the modal to be shown. including #.
 * @param {String|undefined} closeButtonId - the ID of a close button, allowing the modal to be closed manually
 */
function declarePopup(popupId, closeButtonId) {
	"use strict";
	// If the HTML doesn't exist then we don't do anything just in case.
	if($(popupId).length) {

		//handler for modal hiding
		$(popupId).on('hidden.bs.modal', function (e) {
			// Update number of modals open
			console.log("an event to hide a modal was fired");
			popup.modalsOpen --;
			// reattach a fixed focus, only if there are no more modals open.
			if(popup.modalsOpen === 0) {
				if ($('#locationInput').length) {
					attachFixedFocus("#locationInput");
				} else if ($('#scanInput').length) {
					attachFixedFocus("#scanInput");
				} else if ($('#scanInput').length) {
					attachFixedFocus("#scanInput");
				} else if (location.pathname.substring(1) === "dsmenu.php") {
					attachMenuNumbers();
				}
			}
		});

		//handler for the close button
		if($(closeButtonId).length) {
			$(closeButtonId).on('click', function() {
				$(popupId).modal('hide');
			});
		}
	}
}

/**
 * @description Show a popup on screen, and set global controls as appropriate. Used in popups / modals on pages
 * where the code is in the page rather than generated dynamically.
 * Future developments should have the modal code in templates and use this rather than generating a modal dynamic
 * @param {String} popupId - The element ID of the modal to be shown. including #.

 */
function showPopup(popupId) {
	"use strict";
	//Check the modal HTML exists
	if($(popupId).length) {
		$(popupId).modal({backdrop: 'static', keyboard: false, show: true});
		//take care of fixed focuses and handling
		detachFixedFocus();
		detachMenuNumbers();
		attachInputEvents();
		//update global state
		popup.modalsOpen ++;
		console.log("modals:"+popup.modalsOpen);
	}
}

/**
 * @description Creates a generic popup. Individual popup content should be built and then call this to show.
 * @param {String} id
 * @param {String} header
 * @param {String} content
 * @param {Boolean} [close]
 */
function generatePopup(id, header, content, close) {

  detachFixedFocus();
  detachMenuNumbers();
  var showClose = typeof close !== 'undefined' ? close : true;
  var modalName = id + "-modal";
  var modalId = "#" + modalName;
  if ($(modalId).length) {
    $(modalId).modal('show');
  } else {

    var modalCode = '<div class="modal" id="' + modalName + '" tabindex="-1" z-indez="100" role="dialog">' +
      '<div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-header">';

    if (showClose) {
      modalCode += '<button type="button" class="close" data-dismiss="modal" aria-label="close" id="' + modalName + 'Close"><span aria-hidden="true">&times;</span></button>';
    }

    modalCode += '<h2 class="modal-title">' + header + '</h2></div><div id="'+ modalName +'Body" class="modal-body">' +
      content + '</div></div></div></div>';

    $('.container').append(modalCode);
    attachInputEvents();

    popup.modalsOpen ++;
		console.log("modals:"+popup.modalsOpen);
    $(modalId).modal({backdrop: 'static', keyboard: false, show: true});
    var closeButton = modalId + "Close";

    $(closeButton).on("click", function () {
      $(modalId).modal('hide');
    });

    $(modalId).on('hidden.bs.modal', function (e) {
      $(modalId).remove();
      //$(".modal-backdrop").remove();
      popup.modalsOpen --;

			//If no modals are open we can reattach fixed focuses and other tied in handlers
			//Most screens will have the same name for scanning inputs, and the menu requires number capture
			if(popup.modalsOpen === 0) {
				if ($('#locationInput').length) {
					attachFixedFocus("#locationInput");
				} else if ($('#scanInput').length) {
					attachFixedFocus("#scanInput");
				} else if ($('#scanInput').length) {
					attachFixedFocus("#scanInput");
				} else if (location.pathname.substring(1) === "dsmenu.php") {
					attachMenuNumbers();
				}
			}
    });
  }
}

/**
 * All Individual popup code is below, which then call the generic generatePopup function, or declared and shown.
 */

/**
 * @description Add additional options to the popup menu when needed.
 * @param {string} id The ID for the button to be added
 * @param {string} label The label to be shown on the button
 * @param {Boolean} append True if the button should be appended, otherwise it will be added to the start.
 */
function addPopupMenuOption(id, label, append) {
	"use strict";
	if(append) {
		$("#popupMenuOptions").append("<button class='btn btn-dramscan btn-lg btn-block btn-additional' id="+id+">"+label+"</button>");
	} else {
		$("#popupMenuOptions").prepend("<button class='btn btn-dramscan btn-lg btn-block btn-additional' id="+id+">"+label+"</button>");
	}
}

function loadCaskIncidentPopup() {
  "use strict";
	generatePopup("caskIncidentPopup", "Record Incident", "", true);
	$("#caskIncidentPopup-modalBody").load('../popups/ds-cask-incident.popup.html', function() {

		// Setup the dialog
		var labels = getLabels();
		$('#incidentBarcode').focus();
		$("#incidentBarcodeLabel").html('Scan ' + labels.caskLabel + ':');
		if (Boolean(localStorage.getItem("settings/useSWA") === "true")) {
			$("#manualIncidentButtonBlock").show();
		}

		// Setup the function for selecting Manual cask.
		$("#manualIncidentButton").on("click", function () {
			console.log("Incident to be entered manually");
			$("#incidentScanRow").remove();
			$("#manualCaskPlaceholder").load("./templates/ds.cask-manual-form.template.html", function() {
			});
		});

		// On next button press we need to validate the data already entered.
		// Then we hide the inputs and load the incident code / reason inputs.
		$("#confirmIncident").on("click", function () {
			if($("#incidentBarcode").length) {
				// validate the barcode values, as the barcode must have been scanned in.
				var barcodeObject = createBarcodeObject($("#incidentBarcode").val(), "incidentBarcode");
				if (barcodeObject.passed) {
					loadIncidentsForCaskIncident(barcodeObject, true);
				}
			} else {
				// Validate the manual fields as the barcode input is not there.
				// parse and work with the manual entry fields.
				var inputBlend = $("#manualMake").val().toUpperCase();
				var inputFillYear = $("#manualYear").val();
				var inputRotation = $("#manualRotation").val();
				var inputCaskNumber = $("#manualCask").val();
				var inputFillLocation = "ZZ";

				if (inputRotation === "") {
					inputRotation = "0000";
				}
				// Validate cask details done first - this is to show the appropriate errors before encountering a barcode error.
        // todo if we can create a manual barcode object, we change this to check it is valid before continuing.
				if (validateCaskDetails(inputBlend, inputFillYear, inputRotation, inputCaskNumber, inputFillLocation)) {
					var manualBarcodeObject = createBarcodeObject(dataToBarcode(inputBlend, inputFillYear, inputRotation, inputCaskNumber, inputFillLocation));
					loadIncidentsForCaskIncident(manualBarcodeObject, false);
				}
			}
		});
	});
}

/**
 * @description Function fired from cask incident popup to update the popup and show incident codes and reasons.
 * @param barcodeObject
 * @param scanned
 */
function loadIncidentsForCaskIncident(barcodeObject, scanned) {
	"use strict";
	$("#caskIncidentContent").hide();
	$("#sharedIncidentContent").load("./popups/ds.incident-complete.popup.html", function() {

		// Add reason groups based on the CASK type
		var caskIncidentGroups = getManualIncidentGroups("CASK");
		// Add each group as a optgroup, and each incident as an option.
		for (var i=0; i<caskIncidentGroups.length; i++) {
			var groupId = "ioptgroup" + i;
			$("#incidentCodeSelect").append($('<optgroup>', {
				id: groupId,
				label: caskIncidentGroups[i].description
			}));

			for (var j=0; j<caskIncidentGroups[i].incidents.length; j++) {
				$("#"+groupId).append($('<option>', {
					value: caskIncidentGroups[i].incidents[j].incidentCode,
					text: caskIncidentGroups[i].incidents[j].description
				}));
			}
		}

		$("#completeIncident").on("click", function() {

			var incidentCode = $("#incidentCodeSelect").val();
			var incidentReasons = $("#incidentReasonList").val();

			console.log(incidentCode, incidentReasons);
			console.log(barcodeObject);

			if (parseInt(incidentCode) > -1) {
				saveCaskIncidentTransaction(barcodeObject, incidentCode, incidentReasons, scanned, function (success) {
					if (success) {
						$("#caskIncidentPopup-modal").modal('hide');
						showAlert("Incident Saved", {type: "success"});
						if (doesProgramSendTransactions()) {
							sendTransactions(true);
						}
					}
				});
			} else {
				showAlert("Please choose a reason");
			}
		});
	});
}

/**
 * @description Load the Location Incident screen for recording issues with a location
 */
function loadLocationIncidentPopup() {
  "use strict";
  generatePopup("locationIncidentPopup", "Location Incident","", true);
  $('#locationIncidentPopup-modalBody').load('../popups/ds.location-incident.popup.html', function() {
    attachInputEvents();

    //Get labels and warehouse type
    var labels = getLabels();
    var warehouseType = getWarehouseType();
    var warehouseLabels = (warehouseType === "P")? labels.pallet:labels.racked;
    var thisLocation = getCurrentLocation();
    var currentWarehouseMap;
    //Set the default warehouse button
    if (warehouseType === "P") {
      currentWarehouseMap = getLocationMapping(thisLocation.palletisedMap);
      $("#palletWarehouseLabel").addClass("active");
      $("#palletWarehouse").prop("checked", true);
    } else {
      currentWarehouseMap = getLocationMapping(thisLocation.rackedMap);
      $("#rackWarehouseLabel").addClass("active");
      $("#rackWarehouse").prop("checked", true);
    }

    //display fields as appropriate to the warehouse type
    var displayFunction = function(map,selectorId,mappingField, fieldSelector, lastSelector) {
      var $group = $(selectorId);
      var returnSelector = lastSelector;
      if(map[mappingField].length > 0 ) {
        $group.show();
        $(fieldSelector).attr('maxlength',map[mappingField].length);
        returnSelector = fieldSelector;
        createKeyAction(lastSelector,fieldSelector);
      }else {
        $group.hide();
      }
      return returnSelector;
    };

    var renderAndFocus = function(map, labels) {
      //display fields as appropriate
      var lastInput = "#manualWarehouse";
      lastInput = displayFunction(map,"#manualBayGroup","bay", "#manualBay", lastInput);
      lastInput = displayFunction(map,"#manualRackGroup","rack", "#manualRack", lastInput);
      lastInput = displayFunction(map,"#manualLevelGroup","level", "#manualLevel", lastInput);
      //display labels as appropriate
      $("#incidentBayLabel").text(labels.bay+":");
      $("#incidentRackLabel").text(labels.rack+":");
      $("#incidentLevelLabel").text(labels.level+":");
      //focus on warehouse
      $('#manualWarehouse').focus();
    };

    renderAndFocus(currentWarehouseMap,warehouseLabels);

    //Create event handler for switching warehouse type
    $("#rackWarehouseLabel, #palletWarehouseLabel").on("click", function () {
      //Get checked now:
      var selectedVal = $("input[name='warehouseTypes']:checked").val();
      var chosenWarehouseMapping;
      //Check we haven't clicked on the already set type
      if(selectedVal === "P" && event.target.id === "palletWarehouseLabel") {
        $('#manualWarehouse').focus();
        return;
      }
      if(selectedVal === "R" && event.target.id === "rackWarehouseLabel") {
        $('#manualWarehouse').focus();
        return;
      }
      //Set the default warehouse button
      if (selectedVal === "P") {
        chosenWarehouseMapping = getLocationMapping(thisLocation.rackedMap);
        $("#palletWarehouseLabel").removeClass("active");
        $("#palletWarehouse").prop("checked", false);
        $("#rackWarehouseLabel").addClass("active");
        $("#rackWarehouse").prop("checked", true);
      } else {
        chosenWarehouseMapping = getLocationMapping(thisLocation.palletisedMap);
        $("#rackWarehouseLabel").removeClass("active");
        $("#rackWarehouse").prop("checked", false);
        $("#palletWarehouseLabel").addClass("active");
        $("#palletWarehouse").prop("checked", true);
      }
      var newWarehouseLabels = (selectedVal === "P")? labels.racked:labels.pallet;
      renderAndFocus(chosenWarehouseMapping,newWarehouseLabels);
    });

    //Create event handler for when the Confirm button is pressed
    $("#confirmLocationIncident").on("click", function () {

      var charReg = new RegExp("^[0-9a-zA-Z]{0,}$");
        //Get values for each fields
        var selectedWarehouseType = $("input[name='warehouseTypes']:checked").val();
        var errorLabels = (selectedWarehouseType === "P")? labels.pallet:labels.racked;
        var warehouse = $("#manualWarehouse").val(),
          bay = $("#manualBay").val(),
          rack = $("#manualRack").val(),
          level = $("#manualLevel").val();

        //Validate the warehouse
        if(!isValidWarehouse(warehouse,"manualWarehouse",selectedWarehouseType)) {
          return;
        }
        //Validate all other fields are alphanumeric or blank
        if(!charReg.test(bay)) {
          showAlert("Invalid " + errorLabels.bay,{focus:"manualBay"});
          return;
        }
        //Going forward, if the next input has a value then the last must be filled in
        if(!charReg.test(rack)) {
          showAlert("Invalid " + errorLabels.rack,{focus:"manualRack"});
          return;
        } else {
          if(rack !== "" && bay === "") {
            showAlert("Invalid " + errorLabels.bay,{focus:"manualBay"});
            return;
          }
        }
        if(!charReg.test(level)) {
          showAlert("Invalid " + errorLabels.level,{focus:"manualLevel"});
          return;
        } else {
          if(level !== "" && rack === "") {
            showAlert("Invalid " + errorLabels.rack,{focus:"manualRack"});
            return;
          }
        }

        //Pad out the values to the standard format against the location
        //This standard formatting is not being picked up well.
        var mapping = getLocationMapping(thisLocation.labelMap);
        var locationScanned = {
          warehouse: warehouse,
          bay: (bay !== "")?padNumber(mapping.bay.length,bay):"",
          rack: (rack !== "")?padNumber(mapping.rack.length,rack):"",
          level:(level !== "")?padNumber(mapping.level.length,level):""
        };
        //pad values to mapping lengths (no barcode to worry about here either)
        //create transaction

				// load the content for incident codes and reasons.
			  loadIncidentsForLocationIncident(locationScanned);
    });
  });
}

function loadIncidentsForLocationIncident(locationValues) {
  "use strict";

	$("#locationIncidentContent").hide();
	$("#sharedIncidentContent").load("./popups/ds.incident-complete.popup.html", function() {

		// Add reason groups based on the LOCATION type
		var caskIncidentGroups = getManualIncidentGroups("LOCATION");
		// Add each group as a optgroup, and each incident as an option.
		for (var i=0; i<caskIncidentGroups.length; i++) {
			var groupId = "ioptgroup" + i;
			$("#incidentCodeSelect").append($('<optgroup>', {
				id: groupId,
				label: caskIncidentGroups[i].description
			}));

			for (var j=0; j<caskIncidentGroups[i].incidents.length; j++) {
				$("#"+groupId).append($('<option>', {
					value: caskIncidentGroups[i].incidents[j].incidentCode,
					text: caskIncidentGroups[i].incidents[j].description
				}));
			}
		}

		$("#completeIncident").on("click", function() {

			var incidentCode = $("#incidentCodeSelect").val();
			var incidentReasons = $("#incidentReasonList").val();

			console.log(incidentCode, incidentReasons);
			console.log(locationValues);

			if (parseInt(incidentCode) > -1) {
				saveLocationIncidentTransaction(locationValues, incidentCode, incidentReasons, function (success) {
					if (success) {
						$("#locationIncidentPopup-modal").modal('hide');
						showAlert("Incident Saved", {type: "success"});
						if (doesProgramSendTransactions()) {
							sendTransactions(true);
						}
					}
				});
			} else {
				showAlert("Please choose a reason");
			}
		});
	});
}

function generateStatusPopup() {
  showAjaxLoading();
    /**
     * @param {configLoadedCallback}
     */
  getConfig(function(localConfig) {
    $.when(pingServer(localConfig)).always(function (x, y, xhr) {

      hideAjaxLoading();
      var isServerUp;
      var appServerState;

      // declare the rendering function here to be used after online or offline scenarios.
      var renderFunction = function(isServerUp, appServerState, timeIsGood) {
          var onlineStatus = isServerUp ? "Connected" : "Disconnected";
          //if(online) onlineStatus = "Online"; else onlineStatus = "offline";
          countStoredTransactions(function(countedTransactions) {

              var code = '<table class="statustable"><tbody>';
              // content
              var attributes = [{
                  title: "Scanner ID",
                  value: localStorage.getItem("scannerid")
              },
                  {
                      title: "Transactions",
                      value: countedTransactions + " cached"
                  },
                  {
                      title: "System",
                      value: localConfig.environmentName
                  },
                  {
                      title: "Server Status",
                      value: appServerState
                  },
                  {
                      title: "Network Status",
                      value: onlineStatus
                  },
                  {
                      title: "Cache",
                      value: getCurrentCacheState()
                  },
                  {
                      title: "System Time",
                      value: createTransactionDateTime().timeString
                  }];
              if (isServerUp) {
                  if(!timeIsGood)
                  {
                      var errorMessage = {
                          title: "Error",
                          value: "Server time issue"
                      };
                      attributes.push(errorMessage);
                  }
              }

              for (var i = 0; i < attributes.length; i++) {
                  code += '<tr><td><div class="dramscanlabel">' + attributes[i].title + '</div></td><td><div class="dramscanlabel">' + attributes[i].value + '</div></td></tr>';
              }
              code += '</tbody></table>';
              generatePopup("popupStatus", "App Status", code);
              $("#onlinestatusbutton").removeAttr("disabled");
          }); // count stored transactions
      };

      // Now check what the response success was.
        if (y === "success") {
            $('#onlinestatusbutton').text('Online').removeClass("offline").addClass("online");
            isServerUp = true;
            if (!isApplicationInProgram()) {
                sendTransactions(true);
            }

            var ping = x.AppServerStatus.PingStatus;
            appServerState = (ping === "true") ? "Up" : "Down";
            // Now try and save the time from the server headers.
            saveAndCheckServerTime(xhr.getResponseHeader("Date"),function(timeIsGood) {
              renderFunction(isServerUp, appServerState, timeIsGood);
            }); // saveAndCheckServerTime

        } else {
            $('#onlinestatusbutton').text('Offline').removeClass("online").addClass("offline");
            isServerUp = false;
            appServerState = "Down";
            // Now double check the time vs last know server time
            isScannerTimeCorrect(function(timeIsGood) {
                if (timeIsGood) {
                    renderFunction(isServerUp, appServerState, timeIsGood);
                } else {
                    showIncorrectTimeMessage(function() {
                        logOut(false);
                    });
                }
            });
        }
    }); //when
  });
}

function generateScanTestingPopup(barcode) {
  var code = '<form><div class="form-group">' +
    '<label for="multiBarcodeScan">Scan Barcode:</label>' +
    '<input type="text" class="input-lg form-control" id="multiBarcodeScan"></div>' +
    '<div class="form-group"><p>Scans: <span id="multiScanCount">0</span></p></div>';

  generatePopup("multiBarcodePopup", "Multi Scan", code, true);
  var scannedInput = $("#multiBarcodeScan"),
      scannedCount = $("#multiScanCount");

  scannedInput.focus();
  createEnterAction("#multiBarcodeScan", false, function(testValue) {
      if(testValue === barcode) {
          scannedCount.html(parseInt(scannedCount.html())+ 1);
          scannedInput.val("");
      } else {
          showAlert("The barcode has mismatched.",{type:"error",focus:"multiBarcodeScan"});
      }
  });
}

/**
 * @description Shows a list of allocations available to download from the server.
 */
function generateAvailableAllocationPopup() {

  downloadAllocationList(function (successful) {

    if (successful) {
      // we have a list, display the available in a popup to download.
      var available = trimAvailableAllocations(localStorage.getObject("data/exportedAllocations")),
        tableCode = '';
      // create a table with each of the available allocations

      if (available.length > 5) {
        tableCode += '<button class="btn btn-dramscan divscroll dv-up" id="divscrollup">' +
          '<i class="glyphicon glyphicon-arrow-up"></i></button>' +
          '<button class="btn btn-dramscan divscroll dv-down" id="divscrolldown">' +
          '<i class="glyphicon glyphicon-arrow-down"></i></button>' +
          '<div id="allocationcontainer" class="scrolling"><div style="width: 80%;">';
      }
      else {
        tableCode += '<div id="allocationcontainer" class="scrolling"><div class="container" style="width: 90%;">';
      }
      // create buttons to download each, each button will download that allocation, and close the popup.
      if (available.length < 1) {
        tableCode += '<tr><td>No allocations available for download.</td></tr>';
      }

      for (var x = 0; x < available.length; x++) {
        tableCode += '<div class="form-group"><button role="button" class="btn btn-dramscan btn-lg btn-block" onClick="downloadAvailableAllocation(' + available[x] + ')">' + available[x] + '</button></div>';
      }
      tableCode += '</div></div>';

      // generate the popup
      generatePopup("availableAllocationPopup", "Choose an Allocation", tableCode);

      if (available.length > 5) {

        $("#divscrollup").on("click", function (event) {
          event.preventDefault();
          scrollAllocationList("up");
        });

        $("#divscrolldown").on("click", function (event) {
          event.preventDefault();
          scrollAllocationList("down");
        });
      }
    } else {
      // not able to get allocations
      showAlert("Unable to view available allocations");
    }
    $("#available_allocation_button").removeAttr("disabled");
  });
}

/**
 * @description Shows the allocation summary viewed from the allocation management page
 * @param {String|Number} requestNumber the allocation number to view
 */
function LoadAllocationSummaryPopup(requestNumber) {
  "use strict";
  var allocations = getAllocations(),
    index = searchListForItem(allocations, "requestNumber", parseInt(requestNumber)),
    thisAllocation = allocations[index],
    downloadAll = areAllAllocationsDownloaded();
  generatePopup("allocationSummaryPopup", "Allocation","", true);
  $('#allocationSummaryPopup-modalBody').load('../popups/ds.allocation-summary.popup.html', function() {
    if (!downloadAll) {
      $("#removeAllocationButton").show().on("click", function () {
        if (confirm("Are you sure you want to remove this allocation?")) {
          allocations.splice(index, 1);
					setAllocations(allocations);
          $("#allocationSummaryPopup-modal").modal("hide");
          refreshAllocationPage();
        }
      });
    }
    //Assign values to the table
    $("#summaryRequestNumber").html(thisAllocation.requestNumber);
    $("#summaryRequestType").html(thisAllocation.allocationType);
    $("#summaryBlend").html(thisAllocation.blend);
    $("#summaryVersion").html(thisAllocation.requestVersion);
    $("#summaryCasks").html((thisAllocation.casks) ? thisAllocation.casks.length : "0");
    $("#summaryLines").html(thisAllocation.disgorgeLines);

    //Check for order lines to show this type of allocation info
    if (thisAllocation.orderLines) {
      $("#summaryOrderLineCountRow").show();
      $("#summaryRequiredRow").show();
      $("#summaryOrderLineCount").html(thisAllocation.orderLines.length);
      $("#summaryRequired").html(countTotalRequiredCasksWithoutAllocatedStock(thisAllocation.orderLines)); // Set to the total casks Required
      $("#summaryCasksLabel").html('Available');
    }

    //Check if allocation is OPERATION. If so then we need to show the operation type.
    if (thisAllocation.allocationType === "OPERATION") {
        $("#summaryOperationTypeRow").show();
        $("#summaryOperationType").html(thisAllocation.operationType);
    }
  });
}

function loadAllocationDetailPopup() {
  "use strict";
  downloadAllocation(pickingAllocation.number,function(successful) {
    if(successful) {
      updatePickingAllocation();
    }
    //Show popup
    generatePopup("allocationDetailPopup", "Allocation Details","", true);
    $('#allocationDetailPopup-modalBody').load('../popups/ds.allocation-detail.popup.html', function() {
      var remainingCasks = getRemainingCasks();
      $("#summaryRequestNumber").html(pickingAllocation.number);
      $("#summaryVersion").html(pickingAllocation.version);
      $("#summaryBlend").html(getAllocations()[pickingAllocation.index].blend);
      if (pickingAllocation.hasOrderLines) {
        var totalRequiredCasks = countTotalRequiredCasks(pickingAllocation.orderLines, pickingAllocation.casks);
        var remainingRequiredCasks = totalRequiredCasks - (pickingAllocation.casks.length - remainingCasks.length);
        remainingCasks = getAllRemainingOrderLineCasks();
        $("#summaryOrderLineCountRow").show();
        $("#summaryRequiredRow").show();
        $("#summaryOrderLineCount").html(pickingAllocation.orderLines.length);
        $("#summaryRequired").html(totalRequiredCasks);
        $("#summaryCasks").html(remainingRequiredCasks);
      } else {
        $("#summaryCasks").html(remainingCasks.length);
      }
      // Show each remaining in list
      for (var i=0; i<remainingCasks.length; i++) {
        // If we are using wood tracking / Cask Id's we might be prioritising them..
          // todo to remove barcode dependency, change to make, fill year, rotation, and vesselNo padded.
          var renderValue = remainingCasks[i].barcode; //default to the barcode
          if (localStorage.getVariable("settings/scanPriority") === "WTL") {
              if (remainingCasks[i].caskId !== "") {
                  // If caskId is not blank then we can show it
                  renderValue = remainingCasks[i].caskId;
              }
          }
          $("#caskList").append("<li>"+renderValue+"</li>");
      }
    });
  });
}

/**
 * @description Shows a popup to scan a pallet label. Then check it is valid.
 * @param callback(palletNo) A function to run when successful, passing the valid pallet number.
 */
function generateScanPalletPopup(callback) {
  "use strict";
  generatePopup("scanPalletPopup", "Scan Pallet", "", true);
    $('#scanPalletPopup-modalBody').load('../popups/ds.pallet-scan.popup.html', function() {
        $("#palletScan").focus();
        createCompleteAction("#palletScan", "#confirmPalletScanButton");
        $("#confirmPalletScanButton").on("click", function() {
            // validate the input from the pallet scan field, parse to check it is a pallet, and return.
            findBarcodeMatch($("#palletScan").val(), "PALLET", function(matchResult) {
                // The pallet is valid and we will want to tell whatever screen the pallet number.
                $("#palletScan").val('');
                // We also check to see if the pallet has been scanned before as we might have accidentally scanned.
                // This check only works when still on the page. A reload will reset like movement_casks.
                console.log("found a match to the pallet");
                if (palletsScanned.indexOf(matchResult.fields.palletNo) >= 0) {
                    showAlert("This pallet has already been scanned. Do you want to rescan?",
                        {canCancel: true, cancelText: "Keep Pallet No", confirmText: "Rescan", callback: function() {
                            // User has selected to rescan.
                                $("#palletScan").focus();
                        }, cancelCallback: function() {
                                $("#scanPalletPopup-modal").modal("hide");
                                callback(matchResult.fields.palletNo);
                        }});
                } else {
                    // The pallet has never been scanned so use it.
                    $("#scanPalletPopup-modal").modal("hide");
                    callback(matchResult.fields.palletNo);
                }
            }, function() {
                $("#palletScan").val('');
                showAlert("Invalid Pallet.", {focus: "palletScan"});
            });
        });
    });
}

function generateChangeTrailerPopup(callback) {

  var code = '<form><div class="form-group">';
  code += '<label for="trailer">Scan Trailer ID:</label><input type="text" class="input-lg form-control" id="trailer">';
  code += '</div><button id="changeTrailerPopupButton" type="button" class="btn btn-dramscan btn-lg btn-block">Change</button>';

  generatePopup("changeTrailerPopup", "Change Trailer", code);

  createCompleteAction("#trailer", "#changeTrailerPopupButton");
  $("#trailer").focus();
  $("#changeTrailerPopupButton").on("click", function () {
    // update trailer
    if (validateTrailer(stripScanningPrefix($("#trailer").val()))) {
      $("#changeTrailerPopup-modal").modal("hide");
      if (getProgramTransactionCode() === "LOAD") {
        // load transaction - update warehouse and bay
        scanLocation.warehouse = localStorage.getItem("settings/trailerWarehouse"); //todo this is duplicate from elsewhere, should this be moved?
        scanLocation.bay = localStorage.getItem("scanTrailer");
      }
      callback(true);
    } else {
      showAlert("Invalid Trailer.", {focus: "trailer"});
    }
  });
}

/**
 * @description creates a popup to show manual location entry
 * @param callback function called once the enter button has been pressed
 */
function generateManualLocationPopup(callback) {

  var locationMapping = getLocationMapping();
  var parameters = getProgramFields().split(",");
  var labels = getLabels();
  var warehouseType = getProgramWarehouseType();
  var finalinput = "";
  var firstinput = "";
  var inputTransitions = [];
  var inputPair;

  var code = '<form class="form-horizontal">';

  var appendToPopup = function (inputName, label, tabIndex, maxLength) {

    code += '<div class="form-group"><label for="' + inputName + '" class="col-xs-4 control-label">' + label + '</label><div class="col-xs-8"><input type="text" class="form-control input-lg" id="' + inputName + '" tabindex="' + tabIndex + '" maxlength="' + maxLength + '"></div></div>';
    if (firstinput === "") {
      firstinput = "#" + inputName;
    } else {
      var thisInput = "#" + inputName;
      inputPair = {
        enterElement: finalinput,
        goToElement: thisInput
      };
      inputTransitions.push(inputPair);
    }
    finalinput = "#" + inputName;
  };

  var warehouseLabelType = "";
  if (warehouseType === "RACK" || warehouseType === "ALL") {
    warehouseLabelType = "racked";
  } else {
    warehouseLabelType = "pallet";
  }

  for (var i = 0; i < parameters.length; i++) {
    switch (parameters[i]) {
      case "WHS":
        appendToPopup("warehouse", "Whs", i + 1, locationMapping.warehouse.length);
        break;
      case "BAY":
        appendToPopup("bay", labels[warehouseLabelType].bay, i + 1, locationMapping.bay.length);
        break;
      case "RACK":
        appendToPopup("rack", labels[warehouseLabelType].rack, i + 1, locationMapping.rack.length);
        break;
      case "LVL":
        appendToPopup("level", labels[warehouseLabelType].level, i + 1, locationMapping.level.length);
        break;
      case "FB":
        appendToPopup("frontBack", "F/B", i + 1, locationMapping.frontBack.length);
        break;
    }
  }

  code += '<div class="form-group"><div class="col-xs-12"><button type="button" tabindex="6" class="btn btn-dramscan btn-lg btn-block" id="locationPopupConfirm">Enter</button></div></div>';

  generatePopup("manualLocationPopup", "Enter Location", code, true);
  $(firstinput).focus();
  createCompleteAction(finalinput, "#locationPopupConfirm");

  for (var j = 0; j < inputTransitions.length; j++) {
    createKeyAction(inputTransitions[j].enterElement, inputTransitions[j].goToElement);
  }

  $("#locationPopupConfirm").on("click", function () {
    callback();
  });
}

/**
 * @callback manualCallback Callback function to run once manual SWA entry is completed.
 * @param {Boolean} validData - True if the manual data was all valid.
 * @param {barcodeObject|null} manualBarcodeObject - a barcodeObject created on entry of valid data.
 */

/**
 * @description Loads the popup for entering manual SWA cask details - using new template HTML.
 * @param {string} lastScan - String of previous_scan or similar to prepopulate the manual inputs.
 * @param {manualCallback} callback - Function to run when OK is pressed.
 */
function loadManualCaskPopup(lastScan, callback) {
  "use strict";
	generatePopup("manualCaskPopup", "Enter Cask", "", true);
	$('#manualCaskPopup-modalBody').load('../popups/ds.cask-manual.popup.html', function() {
    // The template for the popup has loaded. This uses the same form fields as cask incident (manual)
		// Load that into the correct place.
		$('#manualCaskFormArea').load('../templates/ds.cask-manual-form.template.html', function() {
			// Now we have the form elements.
			// Show the fill location. This defaults to hidden as is hidden in the incident popup
			$("#manualFillLocationGroup").show();
			// Add a complete action and an action to go from cask to fill location.
			createKeyAction("#manualCask", "#manualFillLocation");
			createCompleteAction("#manualFillLocation", "#caskCompleteButton");

			// Show any previous scan information - if setup to do so
			var barcodeMap = getBarcodeMapping(),
				barcodeLength = localStorage.getVariable("settings/classicBarcodeMapping").length;
			// If the caskID was the same length as barcode mapping, this would generate crap in the manual fields here.
			if (lastScan && typeof lastScan === "string" && lastScan.length === barcodeLength) {
				$("#manualMake").val(lastScan.substr(barcodeMap.make.start, barcodeMap.make.length).toUpperCase().trim());
				$("#manualYear").val(lastScan.substr(barcodeMap.fillYear.start, barcodeMap.fillYear.length));
				$("#manualRotation").val(lastScan.substr(barcodeMap.rotation.start, barcodeMap.rotation.length));
				if(localStorage.getItem("settings/prepopulateManualCaskNumber") === "true") {
					$("#manualCask").val(lastScan.substr(barcodeMap.caskNumber.start, barcodeMap.caskNumber.length)).focus();
				}
				$("#manualFillLocation").val(lastScan.substr(barcodeMap.fillLocation.start, barcodeMap.fillLocation.length));
			}

			$('#caskCompleteButton').on('click', function() {
				var inputBlend = $("#manualMake").val().toUpperCase(),
					inputFillYear = $("#manualYear").val(),
					inputRotation = $("#manualRotation").val(),
					inputCaskNumber = $("#manualCask").val(),
					inputFillLocation = $("#manualFillLocation").val().toUpperCase();

				if (inputRotation === "") {
					inputRotation = "0000";
				}
				if(inputFillLocation === "") {
					inputFillLocation = "ZZ";
				}

				// Validate and process manual details.
        // here we create and return a manual Barcode Object if valid. Otherwise we will return null.
				if (validateCaskDetails(inputBlend, inputFillYear, inputRotation, inputCaskNumber, inputFillLocation)) {
				  var manualBarcodeObject = createManualBarcodeObject(inputBlend, inputFillYear, inputRotation, inputCaskNumber, inputFillLocation);
				  callback(manualBarcodeObject.passed, manualBarcodeObject); // Validity returns as the boolean for if the cask object was all okay.
				} else {
				  callback(false, null);
        }
			});
		});
	});
}

function generateTrailerPopup(trailerId) {
  var trailers = getTrailersFromStorage(),
    thisTrailer = searchListForItem(trailers, "id", trailerId.toUpperCase()),
    trailerCasks = trailers[thisTrailer].casks,
    labels = getLabels(),
    displayCask,
    code = '<p>' + labels.caskLabel + 's:</p>',
    popupTitle = "Trailer: " + trailerId;

  if (trailerCasks.length > 10) {
    code += '<button data-role="button" data-theme="h" class="btn btn-dramscan divscroll dv-up" id="divscrollup">' +
      '<i class="glyphicon glyphicon-arrow-up"></i></button>' +
      '<button data-role="button" data-theme="h" class="btn btn-dramscan divscroll dv-down" id="divscrolldown">' +
      '<i class="glyphicon glyphicon-arrow-down"></i></button>';
  }
  code += '<div id="casklistcontainer" class="casklistcontainer"><ul>';

  for (var i = 0; i < trailerCasks.length; i++) {
    displayCask = (trailerCasks[i].caskId) ? trailerCasks[i].caskId : trailerCasks[i].barcode;
    code += '<li>' + displayCask + '</li>';
  }
  code += '</ul></div>';

  generatePopup('trailerSummary', popupTitle, code, true);

  $("#divscrollup").on("click", function (event) {
    event.preventDefault();
    scrollList("up");
  });

  $("#divscrolldown").on("click", function (event) {
    event.preventDefault();
    scrollList("down");
  });
}

function generateTransactionPopup() {
	"use strict";
	var code = '';
	if (scanLocation.capacity !== 999) {
		// Warehouse capacities are being used and set, so lets display a capacity progress.
		code += '<div id="capacityProgress">Warehouse Capacity: '+movement_casks.length+' / '+scanLocation.capacity+
			'</div><hr>';
	}

	code += '<div id="scannedCaskContainer" class="scrolling-container"><ul style="list-style: none; padding-left: 0;">';
 for(var i=movement_casks.length-1;i>=0;i--) {
    code += '<li>'+movement_casks[i]+'</li>';
  }
  code += '</ul></div>';
  generatePopup("transaction","Scan Details",code,true);
  createScrollButtonsForContainer("scannedCaskContainer");
}

/**
 * @description Shows a popup to choose the vacuity type to scan into a warehouse location
 * @param choices An array of vacuity types.
 * @param selectionCallback
 */
function generateVacuityChoicePopup(choices, selectionCallback) {
	"use strict";
	var code = '<div id="choiceContainer">';
	choices.forEach(function(choice) {
		code += '<button class="btn btn-dramscan btn-lg btn-block vacuity-choice-button"' +
			' id="vacuity-'+choice.vacuityType+'" ' +
			'data-capacity="'+choice.capacity+'">'+choice.vacuityType+'</button>';
	});
	code += '</div>';

	generatePopup("vacuityChoice", "Choose Vacuity Type", code, false);
	$(".vacuity-choice-button").on('click', function() {
		var capacityValue = $("#"+this.id).data('capacity');
		$("#vacuityChoice-modal").modal('hide');
		selectionCallback(capacityValue);
	});
}
